<template>
  <div id="app">
    <CounterComp />
  </div>
</template>

<script>
import CounterComp from './components/CounterComp.vue';
// import  from './components/CounterComp.vue';

export default {
  name: 'App',
  components: {
    CounterComp,
  },
};
</script>

<style>
/* Optional global styles */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
