<template>
    <div class="counter">
      <h1>Times Ella said "I'm Tired" this semester.</h1>
      <p>Aktueller Zählerstand: {{ count }}</p>
      <button @click="increment">Increment</button>
      <!-- <button @click="decrement">Runterzählen</button> -->
      <!-- <button @click="reset">Zurücksetzen</button> -->
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "CounterComp",
    data() {
      return {
        count: 0, // Lokaler Zählerstand
      };
    },
    methods: {
      async fetchCounter() {
        try {
          const response = await axios.get("http://synx.network/get_counter.php");
          this.count = response.data.value;
        } catch (error) {
          console.error("Fehler beim Abrufen des Zählerstands:", error);
        }
      },
      async saveCounter() {
        try {
          await axios.post("http://synx.network/update_counter.php", {
            value: this.count,
          });
        } catch (error) {
          console.error("Fehler beim Speichern des Zählerstands:", error);
        }
      },
      increment() {
        this.count++;
        this.saveCounter();
      },
      decrement() {
        this.count--;
        this.saveCounter();
      },
      reset() {
        this.count = 0;
        this.saveCounter();
      },
    },
    mounted() {
      this.fetchCounter(); // Hole den Zählerstand beim Laden der Komponente
    },
  };
  </script>
  
  <style>
  .counter {
    text-align: center;
    margin-top: 50px;
  }
  
  button {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  </style>
  